<template>
    <div class="main">
        <Swiper :modules="modules" :pagination="{ clickable: true }" :autoplay="{ delay: 3000 }">
            <swiper-slide>
                <img class='img' :src="require('/src/assets/16.png')" alt="">
            </swiper-slide>
            <swiper-slide>
                <img class='img' :src="require('/src/assets/5.png')" alt="">
            </swiper-slide>
            <swiper-slide>
                <img class='img' :src="require('/src/assets/4.png')" alt="">
            </swiper-slide>
            <swiper-slide>
                <img class='img' :src="require('/src/assets/11.png')" alt="">
            </swiper-slide>
            <swiper-slide>
                <img class='img' :src="require('/src/assets/12.png')" alt="">
            </swiper-slide>
            <swiper-slide>
                <img class='img' :src="require('/src/assets/18.png')" alt="">
            </swiper-slide>
        </Swiper>
        <div>
            <div class="container" id="background1" style="background-color:white">
                <div class="about">
                    <div id="aboutTitle" style="display:flex;align-items: center;margin:0vh">
                        <h1 style="color:aquamarine">关于NIJIANG</h1>
                        <h4 style="color:grey;margin:1vh;">ABOUT NIJIANG</h4>
                    </div>
                    <div class="paragraph" ref="fadeInText">
                        <p>NIJIANG工作室是集设计、开发、运营和服务为一体的MC新生代小游戏服务器；
                            工作室成立于2024年，现有成员6余人，有信息服务工程师，插件开发工程师，游戏策划师，建筑设计师，产品经理等职位。</p>
                        <p>工作室小游戏项目现有：麦块大战僵尸以及相关的各种衍生模式。</p>
                        <p>NIJIANG工作室目前已运营超过三个月，工作室成员兢兢业业，长了七八个肝，目的就是为各大MC玩家提供高质量游戏体验!</p>
                    </div>
                </div>
                <div class="about">
                    <img class="aboutImg" :src="require('/src/assets/2.png')">
                </div>
            </div>
        </div>

        <div class="video">
            <img :src="require('/src/assets/index3-box.png')" alt="">
            <p>NIJIANG工作室宣传片</p>
            <p>NIJIANG ADVERTISING VIDEO</p>
            <p>以精求特 以专求长</p>
            <button>立即播放</button>
        </div>
    </div>

</template>

<script>
    import {
        Pagination,
        Autoplay
    } from 'swiper';
    import {
        Swiper,
        SwiperSlide
    } from 'swiper/vue';
    import 'swiper/css';
    import 'swiper/css/pagination';
    import 'swiper/css/navigation';

    export default {
        mounted() {
            // 监听页面滚动事件
            window.addEventListener('scroll', this.handleScroll);
        },
        unmounted() {
            // 在组件销毁时移除事件监听
            window.removeEventListener('scroll', this.handleScroll);
        },
        methods: {
            handleScroll() {
                // 获取文字元素
                const textElement = this.$refs.fadeInText;
                // 获取文字元素距离页面顶部的距离
                const textOffsetTop = textElement.offsetTop;
                // 获取页面滚动的距离
                const scrollTop = window.scrollY || document.documentElement.scrollTop;
                // 如果页面滚动的距离超过文字元素距离页面顶部的距离，则添加渐变过渡效果
                if (scrollTop > textOffsetTop - window.innerHeight) {
                    textElement.classList.add('fade-in');
                }
            }
        },
        name: 'homeView',
        components: {
            Swiper,
            SwiperSlide,
        },
        data() {
            return {
                modules: [Pagination, Autoplay],
            };
        },
    };
</script>

<style scoped>
    .main {
        width: 100%;
    }


    .container {
        display: flex;
        justify-content: center;
        /* align-items: center; */
        width: 100%;
        height: auto;
        /* margin-top:2vh; */
        flex-direction: row;
    }


    .img {
        width: 100%;
        height: 50vh;
        object-fit: cover;
        /* 裁剪图片，保持比例填满容器 */
    }

    .video {
        color: white;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 96%;
        height: 50vh;
        margin: 2%;
        margin-top: 0;
        flex-direction: row;
        background-size: cover;
        background-image: url('/src/assets/index3.jpg')
    }

    .video img {
        transition: 0.5s;
        animation: fadeIn 1s forwards;

        position: absolute;
        /* Position the image absolutely */
        top: 50%;
        /* Move the image 50% from the top */
        left: 50%;
        /* Move the image 50% from the left */
        transform: translate(-50%, -50%);
        /* Translate the image back by half of its own width and height */
    }

    .video p {
        font-size: 24px;
    }

    .video p:nth-of-type(1) {
        font-size: 32px;
        position: absolute;
        /* Position the image absolutely */
        top: 30%;
        /* 第一个 <p> 元素在容器的上方 10% 处 */
    }

    .video p:nth-of-type(2) {
        color: grey;
        position: absolute;
        /* Position the image absolutely */
        top: 42%;
        /* 第二个 <p> 元素在容器的上方 30% 处 */
    }

    .video p:nth-of-type(3) {
        color: aquamarine;
        position: absolute;
        /* Position the image absolutely */
        top: 65%;
        /* 第三个 <p> 元素在容器的上方 50% 处 */
    }

    .video button {
        position: absolute;
        bottom: 12%;
        /* Adjust the bottom position of the button */
        left: 50%;
        /* Move it to the center horizontally */
        transform: translateX(-50%);
        /* Translate it back by half of its own width */
        width: 140px;
        height: 40px;
        color: white;
        font-weight: 600;
        font-size: 16px;
        background-color: skyblue;
        border-radius: 20px;
    }

    #background1 {
        padding: 5vh 0;
        margin-bottom: 0;
        background: url('/src/assets/index2.jpg');
    }

    .about {
        font-size: 18px;
        width: 100%;
        max-width: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
        padding: 2vh;
        flex-direction: column;
    }

    .aboutImg {
        width: 100%;
        height: 400px;
        object-fit: cover;
        /* 裁剪图片，保持比例填满容器 */
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    }

    .paragraph p {
        text-indent: 2em;
        /* 2em 大约等于两个字的宽度 */
        margin-bottom: 2vh;
        /* 设置段前空行的大小，可以根据需要调整 */
    }

    .fade-in {
        opacity: 0;
        /* 初始时隐藏文字 */
    }

    .fade-in {
        animation: fadeInAnimation 3s forwards;
        /* 使用@keyframes动画实现渐变过渡效果 */
    }

    @keyframes fadeInAnimation {
        from {
            opacity: 0;
            /* 渐变效果的起始透明度 */
        }

        to {
            opacity: 1;
            /* 渐变效果的目标透明度 */
        }
    }

    @media screen and (max-width: 768px) {

        .video img {
            width: 100%;
            opacity: 0;
            transition: 0.5s;
            animation: fadeIn 1s forwards;
            /* 动画，1秒钟完成从透明到不透明 */
        }

        #aboutTitle {
            flex-direction: column;
        }

        .container {
            display: flex;
            flex-direction: column;
        }

        .about {
            padding: 20px;
            width: auto;
            max-width: none;
            justify-content: center;
            align-items: center;
        }

    }
</style>