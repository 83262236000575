<template>
    <div class="menu">
        <router-link class="link" to="/">首页></router-link>
        <router-link class="link" to="/support">服务与支持></router-link>
        <router-link class="link" to="/support/afterSales">售后网络</router-link>
    </div>

    <div class="content">
        <div class="service-box">
            <div class="text-box">
                <div class="title">玩家服务</div>
                <p>为了快速响应、深度服务玩家，NIJIANG工作室设有3个交流群：</p>
                <p>交流群1 | 群号：772581408</p>
                <p>交流群2</p>
                <p>交流群3</p>
            </div>
            <img class="service-img" :src="require('/src/assets/20200810101633.png')" alt="服务图片">
        </div>
    </div>
</template>

<script>
    export default {
        name: "ServicePage"
    };
</script>

<style scoped>
    .link {
        text-decoration: none;
        color: black;
        /* margin-right: 15px; */
    }

    .router-link-active {
        text-decoration: none;
        color: steelblue !important;
    }

    .link.router-link-exact-active {
        font-weight: bold;
        color: steelblue !important;
    }

    .menu {
        /* position: absolute; */
        display: flex;
        margin: 1vw 1vw 1vw 20vw;
    }

    .content {
        font-family: "微软雅黑", arial;
        margin: 0;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .service-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        max-width: 1200px;
        width: 100%;
    }

    .text-box {
        max-width: 400px;
        width: 100%;
        margin-bottom: 20px;
    }

    .title {
        font-size: 20px;
        color: #343434;
        line-height: 1.5;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    p {
        font-size: 14px;
        color: #676767;
        line-height: 1.5;
        margin-bottom: 10px;
    }

    .service-img {
        width: 100%;
        max-width: 400px;
    }

    @media (min-width: 768px) {
        .service-box {
            flex-direction: row;
            justify-content: space-between;
            text-align: left;
        }

        .text-box {
            text-align: left;
        }
    }
</style>