<template>
    <div class="menu">
        <router-link class="linksElement" to="/">首页</router-link>>
        <router-link class="menuItem" to="/support">服务与支持</router-link>>
        <router-link class="menuItem" to="/support/download">资料下载</router-link>
    </div>

    <!-- 主体内容 -->
    <div class="fuwu-box">
        <div class="fuwu1 tran-box">

            <div class="fuwu1-top">

                <div class="fw1-li">
                    <a href="" target="_blank">
                        <i class="xiazai"></i>
                        <p>麦块大战僵尸客户端(PCL)</p>
                    </a>
                </div>

                <div class="fw1-li">
                    <a href="" target="_blank">
                        <i class="pdf"></i>
                        <p>麦块大战僵尸客户端(HTML)</p>
                    </a>
                </div>

                <div class="fw1-li last">
                    <a href="">
                        <i class="wenti"></i>
                        <p>常见问题</p>
                    </a>
                </div>

            </div>

            <div class="fuwu1-bottom">

                <div class="fwb-li fwb-li1">
                    <a href="javascript:void();" class="fw-li">
                        <img src="">
                        <div class="txt"></div>
                    </a>

                </div>

                <div class="fwb-li fwb-li2">

                    <a href="javascript:void();" class="fw-li">
                        <img src="">
                        <div class="txt"></div>
                    </a>
                    <a href="javascript:void();" class="fw-li fw-li2">
                        <img src="">
                        <div class="txt"></div>
                    </a>

                </div>

                <div class="fwb-li fwb-li3">

                    <a href="javascript:void();" class="fw-li">
                        <img src="">
                        <div class="txt"></div>
                    </a>

                    <a href="javascript:void();" class="fw-li fw-li2">
                        <img src="">
                        <div class="txt"></div>
                    </a>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

</script>

<style scoped>
    .router-link-active {
        text-decoration: none;
        color: steelblue !important;
    }

    a {
        text-decoration: none;
        color: black;
    }

    .router-link-exact-active {
        font-weight: bold !important;
    }

    .menu {
        display: flex;
        margin: 1vw 1vw 1vw 20vw;
    }

    .fuwu-box {
        overflow: hidden;
    }

    .fuwu1 {
        overflow: hidden;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 2.2%;
        padding-bottom: 3.5%;
    }

    .fuwu1-top {
        overflow: hidden;
        border: 1px solid #dadada;
        margin-bottom: 6%;
    }

    .fw1-li i.pdf {
        background: url('/src/assets/pdf.png') no-repeat center center;
    }

    .fw1-li i.xiazai {
        background: url('/src/assets/xiazai.png') no-repeat center center;
    }

    .fw1-li i.wenti {
        background: url('/src/assets/wenhao.png') no-repeat center center;
    }

    .fw1-li {
        display: block;
        float: left;
        width: 33.3%;
        text-align: center;
        padding-top: 3.2%;
        padding-bottom: 1.5%;
        border-right: 1px solid #dadada;
        margin-left: -1px;
        cursor: pointer;
    }

    .fw1-li:last-child {
        border-right: none;
    }

    .fw1-li i {
        width: 64px;
        height: 64px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5.3%;
        background-size: 100% 100% !important;
    }

    .fw1-li p {
        font-size: 14px;
        line-height: 26px;
        color: #000000;
        font-weight: bold;
    }
</style>