<template>
    <!-- 导航 -->
    <div class="menu">
        <router-link class="linksElement" to="/">首页</router-link>>
        <router-link class="menuItem" to="/contact">联系我们</router-link>>
        <router-link class="menuItem" to="/contact/us">联系方式</router-link>
    </div>
    <!-- 主体内容 -->

    <div class="main-box">
        <div class="lianxi-box tran-box">
            <div class="lianxi-l">

                <div class="top">麦块大战僵尸</div>
                <div class="lx-li" style="background-image: 
          url:require('/src/assets/lx-phone.jpg');">
                    <div class="t1">NIJIANG团队</div>
                    <div class="t2">QQ群：772581408</div>
                </div>

                <div class="lx-li" style="background-image: 
          url:require('/src/assets/lx-kf.jpg');">
                    <div class="t1">玩家交流中心</div>
                    <div class="t2">QQ群：772581408</div>
                </div>

                <div class="lx-li" style="background-image: 
          url:require('/src/assets/lx-phone.jpg');">
                    <div class="t1">联系服主</div>
                    <div class="t2">QQ号：3531341457</div>
                </div>

                <!--                <div class="lx-li" style="background-image: 
          url:require('/src/assets/lx-phone.jpg');">
                    <div class="t1">人力资源部</div>
                    <div class="t2">电话：86-757-23666591（招聘）</div>
                    <div class="t2">电话：86-757-23663636 (传真）</div>
                    <div class="t2">电话：86-757-23663307（前台）</div>
                </div> -->

                <!--                <div class="lx-li" style="background-image: 
          url:require('/src/assets/lx-add.jpg');">
                    <div class="t1">地址</div>
                    <div class="t2">广东省广州市天河区</div>
                </div> -->

                <div class="lx-li" style="background-image: 
          url:require('/src/assets/lx-em.jpg');">
                    <div class="t1">邮箱</div>
                    <div class="t2" style="width:1000px;">3531341457@qq.com（招聘）</div>
                </div>

            </div>

        </div>
    </div>
</template>


<script>

</script>

<style scoped>
    .router-link-active {
        text-decoration: none;
        color: steelblue !important;
    }

    a {
        text-decoration: none;
        color: black;
    }

    .router-link-exact-active {
        font-weight: bold !important;
    }

    .menu {
        /* position: absolute; */
        display: flex;
        margin: 1vw 1vw 1vw 20vw;
    }

    .main-box {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        padding-top: 2.1%;
    }

    * {
        padding: 0;
        margin: 0;
        outline: 0;
        font-family: "微软雅黑", arial;
        text-decoration: none;
        list-style: none;
    }

    .lianxi-box {
        overflow: hidden;
        max-width: 1000px;
        padding: 20px;
        padding-top: 4.5%;
        padding-bottom: 5.5%;
    }

    .lianxi-l {
        float: left;
        overflow: hidden;
        width: 100%;
        padding-right: 1%;
    }

    .lianxi-box .top {
        font-size: 16px;
        color: #000000;
        line-height: 24px;
        overflow: hidden;
        margin-bottom: 25px;
        padding-left: 10px;
    }

    .lianxi-box .lx-li {
        overflow: hidden;
        /* width: 100%; */
        margin-bottom: 20px;
        background-repeat: no-repeat;
        background-position: left center;
        padding-left: 50px;
    }

    .lianxi-box .lx-li .t1 {
        font-size: 14px;
        color: #6c6d78;
        line-height: 20px;
        overflow: hidden;
        margin-bottom: 5px;
    }

    .lianxi-box .lx-li .t2 {
        font-size: 16px;
        color: #000000;
        line-height: 20px;
        overflow: hidden;
    }
</style>