<template>
    <div class="cp-bottom">

        <div class="cp-btt">玩法介绍</div>

        <div class="cp-btxt">
            <ul>
                <li v-for="(parameter, index) in performanceParameters" :key="index">
                    {{ index + 1 }}. {{ parameter }}
                </li>
            </ul>
        </div>

        <div class="cp-btt">规则说明</div>

        <div class="cp-btxt">
            <!-- Add your functionality explanation here if needed -->
        </div>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                performanceParameters: [
                    '人数：每个房间可容纳10-20人。',
                    '玩法：双方分别召唤植物打败僵王博士即可获胜。',
                    '植物能源：植物能源产率随着海龟数增加',
                    '其他：玩家可在召唤界面进入商店购买装备道具/增益。',
                    '其他：僵尸会掉落绿宝石用于植物方购买商店物品。',
                ]
            };
        },
    };
</script>

<style scoped>
    .router-link-active {
        text-decoration: none;
        background: #bfbfbf;
        color: #fff;
        border-radius: 5px;
    }

    a {
        text-align: center;
        padding: 10px;
        font-size: 16px;
        text-decoration: none;
        color: black;
    }

    .router-link-exact-active {
        font-weight: bold !important;
    }

    .menu {
        padding: 10px;
        /* position: absolute; */
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: #f0f0f0;
        border-radius: 10px;
        margin-bottom: 5vh;
    }

    .cp-bottom {
        overflow: hidden;
    }

    .cp-bottom .cp-btt {
        line-height: 62px;
        overflow: hidden;
        border-bottom: 1px solid #8c8c8c;
        font-size: 20px;
        color: #343434;
        font-weight: 500;
    }

    .cp-bottom .cp-btxt {
        font-size: 14px;
        overflow: hidden;
        color: #676767;
        line-height: 30px;
        padding: 20px 0px;
        margin-bottom: 20px;
    }

    table {
        display: table;
        border-collapse: separate;
        box-sizing: border-box;
        text-indent: initial;
        border-spacing: 2px;
        border-color: gray;
    }

    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
    }
</style>