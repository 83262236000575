import {
    createRouter,
    createWebHashHistory
} from "vue-router"
import homeView from "../views/home.vue"
import aboutView from "../views/about.vue"
import contactView from "../views/contact.vue"
import newsView from "../views/news.vue"
import supportView from "../views/support.vue"
import productView from "../views/product.vue"

import profileView from "../views/about/profile.vue"
import honorView from "../views/about/honor.vue"
import RaDView from "../views/about/RaD.vue"
import makeView from "../views/about/make.vue"

import productSort from "../views/product/productSort.vue"
import sAll from "../views/product/sAll.vue"
import s1 from "../views/product/sort/s1.vue"
import s2 from "../views/product/sort/s2.vue"
import p1_1 from "../views/product/sort/pages/p1_1.vue"
import p1_2 from "../views/product/sort/pages/p1_2.vue"
import p1_3 from "../views/product/sort/pages/p1_3.vue"
import p2_1 from "../views/product/sort/pages/p2_1.vue"
import p2_2 from "../views/product/sort/pages/p2_2.vue"
import p2_3 from "../views/product/sort/pages/p2_3.vue"

import companyNews from "../views/news/companyNews.vue"
import new1 from "../views/news/newsContent/new1.vue"
import new2 from "../views/news/newsContent/new2.vue"


import contactUs from "../views/contact/contactUs.vue"
import recruitTalents from "../views/contact/recruitTalents.vue"

import afterSales from "../views/support/afterSales.vue"
import downloadView from "../views/support/download.vue"
import questionView from "../views/support/questions.vue"

const routes = [{
        path: "/",
        name: "首页",
        component: homeView,
    },
    {
        path: "/about",
        name: "关于",
        redirect: "/about/profile",
        component: aboutView,
        children: [{
                path: "profile",
                name: "简介",
                component: profileView,
            },
            {
                path: "honor",
                name: "截图",
                component: honorView,
            },
            {
                path: "RaD",
                name: "开发",
                component: RaDView,
            },
            {
                path: "make",
                name: "制造",
                component: makeView,
            }
        ]
    },
    {
        path: "/product",
        name: "游戏",
        redirect: "/product/sort",
        component: productView,
        children: [{
                path: "sort",
                name: "目录",
                component: productSort,
            },
            {
                path: "sAll",
                name: "全部",
                component: sAll,
                redirect: "/product/sAll/s1",
                children: [{
                        path: "s1",
                        name: "麦块大战僵尸",
                        component: s1,
                        redirect: "/product/sAll/s1/p1_1",
                        children: [{
                                path: "p1_1",
                                name: "麦块大战僵尸1",
                                component: p1_1,
                            },
                            {
                                path: "p1_2",
                                name: "麦块大战僵尸2",
                                component: p1_2,
                            },
                            {
                                path: "p1_3",
                                name: "麦块大战僵尸3",
                                component: p1_3,
                            },
                        ],
                    },
                    {
                        path: "s2",
                        name: "分类2",
                        component: s2,
                        redirect: "/product/sAll/s2/p2_1",
                        children: [{
                                path: "p2_1",
                                name: "金麦块之战1",
                                component: p2_1,
                            },
                            {
                                path: "p2_2",
                                name: "金麦块之战2",
                                component: p2_2,
                            },
                            {
                                path: "p2_3",
                                name: "金麦块之战3",
                                component: p2_3,
                            },
                        ],
                    },
                ]
            },
        ]
    },
    {
        path: "/news",
        name: "新闻",
        redirect: "/news/cnews",
        component: newsView,
        children: [{
                path: "cnews",
                name: "目录⠀",
                component: companyNews,
            },
            {
                path: "new1",
                name: "新闻1",
                component: new1,
            },
            {
                path: "new2",
                name: "新闻2",
                component: new2,
            },
        ]
    },
    {
        path: "/contact",
        name: "联系",
        redirect: "/contact/us",
        component: contactView,
        children: [{
                path: "us",
                name: "联系方式",
                component: contactUs,
            },
            {
                path: "rt",
                name: "大佬募集",
                component: recruitTalents,
            },

        ]
    },
    {
        path: "/support",
        name: "支持",
        redirect: "/support/afterSales",
        component: supportView,
        children: [{
                path: "afterSales",
                name: "玩家服务",
                component: afterSales,
            },
            {
                path: "download",
                name: "下载中心",
                component: downloadView,
            },
            {
                path: "questions",
                name: "常见问题",
                component: questionView,
            },
        ]
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router;