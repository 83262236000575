<template>
    <!-- 导航 -->
    <div class="menu">
        <router-link class="linksElement" to="/">首页</router-link>>
        <router-link class="linksElement" to="/news">新闻中心</router-link>>
        <router-link class="linksElement" to="/news/">新闻</router-link>
    </div>

    <div class="new-xq tran-box">
        <div class="title">{{ newsItem.title }}</div>
        <div class="time">{{ newsItem.date }}</div>

        <div class="txt-box">
            <div class="about">
                <div class="paragraph">
                    <p v-for="(paragraph, index) in newsItem.content" :key="index">{{ paragraph }}</p>
                </div>
            </div>

            <!-- 图片 -->
            <img class="img" :src="newsItem.imageSrc" alt="News Image">
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                newsItem: {
                    title: '新游戏-麦块大战僵尸',
                    date: '2024-10-24 18:00',
                    content: [
                        '2024年10月，NIJIANG团队推出了麦块大战僵尸(竞技场模式)。',
                        '人数：每个房间可容纳2-4人，植物方1-3人，僵尸方1人。',
                        '玩法：双方分别召唤植物/僵尸打败对方的核心即可获胜。',
                        '僵尸能源: 僵尸能源产率随着时间增加',
                        '植物能源: 植物能源产率随着海龟数增加',
                        '其他：玩家可在召唤界面进入商店购买装备道具/增益。',
                        '僵尸会掉落绿宝石用于植物方购买商店物品。',
                    ],
                    imageSrc: require('@/assets/5.png')
                }
            };
        }
    };
</script>

<style scoped>
    .router-link-active {
        text-decoration: none;
        color: steelblue !important;
    }

    .router-link-exact-active {
        font-weight: bold !important;
    }

    a {
        text-decoration: none;
        color: black;
    }

    .menu {
        /* position: absolute; */
        display: flex;
        margin: 1vw 1vw 1vw 20vw;
    }

    .new-xq {
        overflow: hidden;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 2%;
        padding-bottom: 4.2%;
    }

    .new-xq .title {
        text-align: center;
        font-size: 35px;
        color: #343434;
        line-height: 50px;
        margin-bottom: 8px;
    }

    .new-xq .time {
        margin-bottom: 60px;
        font-size: 14px;
        text-align: center;
        color: #343434;
        line-height: 20px;
    }

    .new-top {
        overflow: hidden;
        padding: 1.8% 2%;
        border-bottom: 1px solid #e0e0e0;
    }

    .new-xq .txt-box {
        display: flex;
        justify-content: center;
        align-items: center;

        flex-direction: column;
    }

    .new-pags {
        overflow: hidden;
        display: table;
        margin: 3% auto;
    }

    .img {
        border: 2px solid #bebebe;
        padding: 10px;
        margin: 5vh 0vw;
        width: 90%;
    }

    .text {
        text-align: center;
    }

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        padding-top: 5vh;
        padding-bottom: 5vh;
        margin-top: 5vh;
        flex-direction: column;
    }

    .about {
        font-size: 18px;
        height: auto;
    }

    .aboutText {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        flex-direction: column;
    }

    .paragraph p {
        text-indent: 2em;
        /* 2em 大约等于两个字的宽度 */
        margin-bottom: 2vh;
        /* 设置段前空行的大小，可以根据需要调整 */
    }
</style>