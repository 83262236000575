<template>
    <div class="cp-list">
        <div class="menu">
            <router-link v-for="(item, index) in links" :key="index" :to="item.route">{{ item.label }}</router-link>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                links: [{
                        label: '麦块大战僵尸',
                        route: '/product/sAll/s1'
                    },
                    {
                        label: '金麦块之战',
                        route: '/product/sAll/s2'
                    },
                    {
                        label: '皇室麦块战争',
                        route: '/product/sAll/s3'
                    },
                    {
                        label: '黑神话麦块',
                        route: '/product/sAll/s4'
                    },
                    {
                        label: '瓦罗兰块',
                        route: '/product/sAll/s5'
                    },
                    {
                        label: '抽象起床',
                        route: '/product/sAll/s6'
                    },
                    {
                        label: '抽象PVP',
                        route: '/product/sAll/s7'
                    },
                    {
                        label: '敬请期待',
                        route: '/product/sAll/s8'
                    },
                ],
            };
        },
    };
</script>

<style scoped>
    .router-link-active {
        text-decoration: none;
        background: #bfbfbf;
        color: #fff;
        border-radius: 5px;
    }

    a {
        text-align: center;
        padding: 10px;
        font-size: 16px;
        text-decoration: none;
        color: black;
    }

    .router-link-exact-active {
        font-weight: bold !important;
    }

    .menu {
        padding: 10px;
        /* position: absolute; */
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: #f0f0f0;
        border-radius: 10px;
        margin-bottom: 5vh;
    }

    .cp-list {
        overflow: hidden;
        max-width: 80vw;
        margin-left: auto;
        margin-right: auto;
        padding-top: 2%;
    }

    @media screen and (max-width: 768px) {
        .cp-list {
            max-width: 95vw;
        }
    }
</style>