<template>
    <div class="menu">
        <router-link class="linksElement" to="/">首页></router-link>
        <router-link class="menuItem" to="/support">服务与支持></router-link>
        <router-link class="menuItem" to="/support/questions">常见问题</router-link>
    </div>

    <div class="fuwu-box"></div>

    <div class="fuwu2" id="fuwu2">
        <div class="top tran-box">
            <span class="title">常见问题</span>
            <span class="en">problem</span>
        </div>

        <div class="fw2-box tran-box">
            <div class="fw2-li">
                <div class="faq tran5">启动器一直下载</div>
                <div class="as">
                    <p>使用代理网络。也就是。</p>
                </div>
            </div>
            <div class="fw2-li">
                <div class="faq tran5">游戏进不去</div>
                <div class="as">自行百度启动器报错原因。</div>
            </div>
            <div class="fw2-li">
                <div class="faq tran5">服务器出BUG了</div>
                <div class="as">在QQ群或游戏里给服主或者管理员反馈。</div>
            </div>
            <div class="fw2-li">
                <div class="faq tran5">服务器会跑路吗</div>
                <div class="as">当然不会。</div>
            </div>
            <div class="fw2-li">
                <div class="faq tran5">服务器是圈钱的吗</div>
                <div class="as">当然不是。</div>
            </div>
            <div class="fw2-li">
                <div class="faq tran5">在哪里氪金</div>
                <div class="as">目前是公益的，但如果真的想氪金，请私聊服主。</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FAQComponent'
    };
</script>

<style scoped>
    .router-link-active {
        text-decoration: none;
        color: steelblue !important;
    }

    a {
        text-decoration: none;
        color: black;
    }

    .router-link-exact-active {
        font-weight: bold !important;
    }

    .menu {
        display: flex;
        margin: 1vw 1vw 1vw 20vw;
    }

    .fuwu-box {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2vh;
        overflow: hidden;
    }

    .fuwu2 {
        margin-bottom: 5vh;
        overflow: hidden;
        background: #f8f8f8;
    }

    .top {
        overflow: hidden;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        line-height: 30px;
        padding-bottom: 2%;
        padding-top: 2%;
        text-align: center;
    }

    .fw2-box {
        width: 90%;
        overflow: hidden;
        margin: 0vh 5vw;
        padding-bottom: 1%;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-wrap: wrap;
    }

    .fw2-li {
        overflow: hidden;
        width: 50%;
        padding-bottom: 11px;
    }

    .faq {
        font-size: 20px;
        color: #343434;
        line-height: 47px;
        overflow: hidden;
        position: relative;
        padding-left: 90px;
        padding-top: 14px;
        margin-bottom: 10px;
        display: table;
    }

    .as {
        overflow: hidden;
        padding-left: 90px;
        font-size: 14px;
        color: #646464;
        position: relative;
        height: 80px;
        line-height: 22px;
        overflow: hidden;
        text-align: justify;
        max-width: 365px;
    }

    .faq:before {
        display: block;
        width: 60px;
        height: 47px;
        background: #dbdbdb;
        content: 'FAQ';
        text-align: center;
        color: #72767f;
        font-weight: 300;
        float: left;
        position: absolute;
        left: 0px;
        top: 0px;
        transition: all 0.5s ease;
    }

    .faq:after {
        content: '';
        height: 2px;
        width: 100%;
        background: #2a6ebb;
        position: absolute;
        bottom: 4px;
        left: 100%;
        transition: all 0.5s ease;
    }

    .as:before {
        content: 'A';
        font-size: 24px;
        color: #959595;
        font-weight: bold;
        position: absolute;
        width: 60px;
        left: 0px;
        top: 0px;
        text-align: center;
    }

    @media only screen and (max-width: 768px) {
        .menu {
            flex-direction: column;
            align-items: center;
            margin: 1vw;
        }

        .fw2-li {
            width: 100%;
            padding-bottom: 20px;
        }

        .fw2-li .faq {
            font-size: 18px;
            padding-left: 70px;
            padding-top: 10px;
        }

        .fw2-li .as {
            padding-left: 70px;
            font-size: 12px;
            line-height: 20px;
            max-width: 100%;
        }

        .fw2-li .faq:before {
            width: 50px;
            height: 37px;
            font-size: 16px;
        }

        .fw2-li .as:before {
            width: 50px;
            font-size: 20px;
        }
    }
</style>