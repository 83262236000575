<template>
    <div class="cp-lis">
        <router-link v-for="(link, index) in sublinks" :key="index" :to="link.route" class="cp-li">
            <div class="img"><img class="img" :src="link.url"></div>
            <div class="title">{{ link.label }}</div>
            <div class="more">查看详情</div>
        </router-link>
    </div>

    <router-view></router-view>
</template>

<script>
    // Import Vue Router
    import {
        RouterLink
    } from 'vue-router';

    export default {
        data() {
            return {
                sublinks: [{
                        label: '狂暴模式',
                        route: '/product/sAll/s2/p2_1',
                        url: require('/src/assets/16.png'),
                    },
                    {
                        label: '排位模式',
                        route: '/product/sAll/s2/p2_2',
                        url: require('/src/assets/16.png'),
                    },
                    {
                        label: '恭喜发财',
                        route: '/product/sAll/s2/p2_3',
                        url: require('/src/assets/16.png'),
                    }
                ],
            };
        },
        methods: {

        },
        components: {
            // Register Vue Router's RouterLink component
            RouterLink
        }
    };
</script>

<style scoped>
    .router-link-active {
        text-decoration: none;
        background: #bfbfbf;
        color: #fff;
        border-radius: 5px;
    }

    a {
        border: 2px dashed rgb(0, 0, 0, 0);
        text-align: center;
        padding: 10px;
        font-size: 16px;
        text-decoration: none;
        color: black;
    }

    .router-link-exact-active {
        border: 2px dashed rgb(0, 0, 0, 0.2);
        border-radius: 5px;
        background-color: rgb(0, 0, 0, 0.2);
        font-size: 24px;
        font-weight: bold !important;
    }

    .menu {
        padding: 10px;
        /* position: absolute; */
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: #f0f0f0;
        border-radius: 10px;
        margin-bottom: 5vh;
    }

    .cp-lis {
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-wrap: nowrap;
        overflow: hidden;
        border-top: 1px solid #e8e8e8;
        margin-top: 4%;
        padding-top: 2%;
        /* padding-bottom: 5%; */
    }

    .cp-lis .cp-li {
        overflow: hidden;
        display: block;
        background: none;
        text-align: center;
        float: left;
        width: 30%;
        padding-bottom: 2%;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    .cp-list {
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
        padding-top: 2%;
    }

    .cp-lis .cp-li .img {
        overflow: hidden;
        /* width: 240px; */
        height: 200px;
        object-fit: cover;
        /* 裁剪图片，保持比例填满容器 */
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        margin-bottom: 20px;
    }

    .cp-lis .cp-li .img img {
        width: 100%;
        /* height: 90%; */
        object-fit: cover;
        /* 裁剪图片，保持比例填满容器 */
    }

    .cp-lis .cp-li .title {
        color: #000000;
        line-height: 24px;
        overflow: hidden;
        margin-bottom: 10%;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
</style>