<template>
    <!-- 导航 -->
    <div class="Nav">
        <router-link class="linksElement" to="/">首页</router-link>>
        <router-link class="linksElement" to="/about">关于NIJIANG</router-link>>
        <router-link class="linksElement" to="/about/make">精益制造</router-link>
    </div>
    <!-- 正文 -->
    <div class="container">
        <div class="about">
            <div class="aboutText">
                <div style="display:flex;align-items:center;margin:0vh">
                    <h1 style="color:aquamarine">创意工坊</h1>
                </div>
                <h4 style="color:grey;margin:1vh;">Lean manufacturing</h4>
                <div class="paragraph" ref="fadeInText">
                    <p>NIJIANG工作室是一个注重创新和创意的小游戏服务器；</p>
                    <p>开掘脑洞！这里有各种各样抽象、新奇的小游戏！</p>
                    <p>NIJIANG工作室目前已推出一系列创新小游戏，工作室成员为了各大MC玩家的高质量游戏体验也是献出自己宝贵的肝!</p>
                </div>
            </div>
        </div>
    </div>

    <!-- 其他 -->
    <div class="container" style="background-color:ghostwhite;">
        <div class="title">
            <p1>资料图片</p1>
        </div>
        <img class="img" :src="require('/src/assets/22.png')">
    </div>

</template>

<script>
    export default {
        name: 'honorView',
    };
</script>

<style scoped>
    .img {
        margin: 1vh 1vh 5vh 1vh;
        width: 20%;
    }

    .text {
        text-align: center;
    }

    .title {
        font-size: 24px;
        color: lightblue;
        display: flex;
    }

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        padding-top: 5vh;
        padding-bottom: 5vh;
        margin-top: 5vh;
        flex-direction: column;
    }

    .about {
        font-size: 18px;
        width: 90%;
        max-width: 800px;
        height: auto;
    }

    .aboutText {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        flex-direction: column;
    }

    .paragraph p {
        text-indent: 2em;
        /* 2em 大约等于两个字的宽度 */
        margin-bottom: 2vh;
        /* 设置段前空行的大小，可以根据需要调整 */
    }

    .paragraph p {
        text-indent: 2em;
        /* 2em 大约等于两个字的宽度 */
        margin-bottom: 2vh;
        /* 设置段前空行的大小，可以根据需要调整 */
    }

    .router-link-active {
        text-decoration: none;
        color: steelblue !important;
    }

    a {
        text-decoration: none;
        color: black;
    }

    .router-link-exact-active {
        font-weight: bold !important;
    }

    .Nav {
        /* position: absolute; */
        display: flex;
        margin: 1vw 1vw 1vw 20vw;
    }
</style>