<template>
    <div class="main">
        <button @click="toggleNav" :class="sideNav ? 'menu-btn-open' : 'menu-btn'" class="menu-btn">
            {{menuContent}}</button>

        <div class="main-content" @click="closeSidebar">
            <!-- 导航栏-路由 -->
            <div class="header">
                <img id='logo1' style="width:129px" @click="redirectToRoute" :src="require('/src/assets/nijiang.png')">
                <div class="links">
                    <router-link class="linksElement" to="/">首页</router-link>
                    <router-link class="linksElement" to="/about">关于NIJIANG</router-link>
                    <router-link class="linksElement" to="/product">玩法介绍</router-link>
                    <router-link class="linksElement" to="/news">新闻中心</router-link>
                    <router-link class="linksElement" to="/contact">联系我们</router-link>
                    <router-link class="linksElement" to="/support">玩家服务</router-link>
                </div>
                <img @click="OpenVxQRcode" id="wx1" style="width:20px; margin-left:20px;"
                    :src="require('/src/assets/qq1.png')">
                <br>
                <div id="EN1" class="EN">EN</div>
                <!-- <p>当前路径：{{ $route.path }}</p> -->

                <a id="cd-menu-trigger" href="#0">
                    <span class="cd-menu-icon">
                    </span>
                </a>

            </div>

            <!-- 主体内容 -->

            <!-- 回到顶部 -->
            <div class="button-side">
                <text @click="toTop" class="button-toTop">回到顶部</text>
            </div>

            <!-- 微信二维码 -->
            <div @click="CloseVxQRcode" :class="{ 'vxQRcode-open': vxQRcode }" class="vxQRcode">
                <div class="image-container">
                    <img :style="{ width: vxQRcode ? '321px' : '0', height: vxQRcode ? '570px' : '0' }"
                        :src="require('/src/assets/qq.png')">
                </div>
            </div>

            <div class="placeholder"></div> <!--占位符-->
            <router-view></router-view>

            <!-- 底部栏 -->
            <bottomMenu />
        </div>

        <!-- 侧边栏-路由 -->
        <div :class="{ 'sidebar-open': sideNav }" class="sidebar">
            <div class="linksRow">
                <img id='logo2' style="width:129px" @click="redirectToRoute" :src="require('/src/assets/nijiang.png')">
                <br><br><br><br>
                <router-link class="linksElementS" to="/">首页</router-link>
                <router-link class="linksElementS" to="/about">关于NIJIANG</router-link>
                <router-link class="linksElementS" to="/product">玩法介绍</router-link>
                <router-link class="linksElementS" to="/news">新闻中心</router-link>
                <router-link class="linksElementS" to="/contact">联系我们</router-link>
                <router-link class="linksElementS" to="/support">服务与支持</router-link>
                <br><img id="wx2" style="width:20px;" @click="OpenVxQRcode" :src="require('/src/assets/qq1.png')">
                <br>
                <div id="EN2" class="EN">EN</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                vxQRcode: false,
                sideNav: false,
                menuContent: "菜单",
            };
        },
        methods: {
            toggleNav() {
                if (this.sideNav) this.menuContent = "菜单";
                else this.menuContent = "X";
                this.sideNav = !this.sideNav;
                console.log("切换sideNav");
            },
            OpenVxQRcode() {
                this.vxQRcode = 1;
                console.log("开启vxQR");
            },
            CloseVxQRcode() {
                this.vxQRcode = 0;
                console.log("关闭vxQR");
            },
            redirectToRoute() {
                this.$router.push('/');
            },
            toTop() {
                // 在进入路由前重置视图到顶部
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            closeSidebar() {
                if (this.sideNav) {
                    this.toggleNav(); // Close sidebar if it's open
                    console.log("关闭");
                }
            },
        },
        components: {
            bottomMenu
        }
    };

    import bottomMenu from "@/components/bottomMenu.vue";
</script>

<style scoped>
    .main-content {
        width: 100%;
        -webkit-user-select: none;
        /* Safari */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* IE 10+ */
        user-select: none;
        /* Standard syntax */
    }

    #logo1,
    #logo2,
    #wx1,
    #wx2,
    #EN1,
    #EN2 {
        cursor: pointer;
    }

    .vxQRcode {
        width: 100%;
        height: 100%;
        position: fixed;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0);
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s ease, background-color 0.5s ease, visibility 0.5s;
        z-index: 100;
    }

    .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .vxQRcode img {
        transition: width 0.5s ease, height 0.5s ease;
    }

    .vxQRcode-open {
        opacity: 1;
        visibility: visible;
        background-color: rgba(255, 255, 255, 0.5);
    }

    .header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        height: 80px;
        width: 100%;
        /* margin-top: -1vh; */
        margin-bottom: 0;
        position: fixed;
        background: #fff;
        z-index: 5;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        /* 水平偏移量，垂直偏移量，模糊半径，阴影颜色 */
    }

    .placeholder {
        height: 80px;
        width: 100%;
    }

    .links {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0vw;
        gap: 1vw;
    }

    .linksElement {
        font-size: 14px;
        margin: 1vw;
    }

    .EN {
        color: #9f9f9f;
        display: flex;
        justify-content: center;
        margin: 2vw;
        width: 40px;
        border-radius: 1vh;
        border: 1px solid rgb(164, 164, 164);
    }

    .menu-btn {
        color: black;
        background-color: aliceblue;
        border: 2px solid rgb(165, 165, 165);
        border-radius: 20%;
        width: 50px;
        height: 50px;
        top: 15px;
        right: 2vw;
        position: fixed;
        display: none;
        /* 默认隐藏按钮 */
        transition: box-shadow 0.5s, right 0.2s;
        /* 添加过渡动画 */
        box-shadow: 0px 0px 10px rgb(85, 150, 230, 0.0);
        /* 水平偏移量，垂直偏移量，模糊半径，阴影颜色 */
        z-index: 8;
    }

    .menu-btn-open {
        color: black;
        background-color: aliceblue;
        border: 2px solid rgb(165, 165, 165);
        border-radius: 20%;
        width: 50px;
        height: 50px;
        top: 15px;
        right: 190px;
        position: fixed;
        display: none;
        /* 默认隐藏按钮 */
        box-shadow: 0px 0px 10px rgb(85, 150, 230, 0.8);
        /* 水平偏移量，垂直偏移量，模糊半径，阴影颜色 */
    }

    .sidebar {
        z-index: 10;
        position: fixed;
        top: -2px;
        right: -185px;
        /* 初始隐藏侧边栏 */
        width: 180px;
        height: 100%;
        border: 2px solid #dedede;
        background-color: rgba(255, 255, 255, 0);
        /* background-color: transparent; */
        /* 背景模糊 */
        backdrop-filter: blur(10px);
        /* 这里是模糊效果，数值越大模糊越强 */

        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);

        display: flex;
        flex-direction: column;
        align-items: center;

        /* 水平偏移量，垂直偏移量，模糊半径，阴影颜色 */
        transition: right 0.2s;
        /* 添加过渡动画 */
    }

    .sidebar-open {
        right: 0;
        /* 显示侧边栏 */
    }

    .linksElementS {
        width: 85%;
        text-align: center;
        padding: 1vh;
        border: 1px solid #eaeaea;
        background-color: aliceblue;
        border-radius: 10vw;
        font-size: 14px;
        margin-top: 2vh;
    }

    .linksRow {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1vw;
        padding: 1vw;
    }

    .router-link-active {
        text-decoration: none;
        color: steelblue;
        position: relative;
        /* 设置为相对定位，以便为后续的伪元素定位 */
    }

    .router-link-active::after {
        content: '';
        /* 必须设置，否则伪元素不会显示 */
        position: absolute;
        /* 绝对定位，相对于其父元素（.router-link-active） */
        top: 120%;
        /* 将倒三角定位到链接文本的下方 */
        left: 50%;
        /* 将倒三角定位到链接文本的中心 */
        margin-left: -5px;
        /* 微调位置，确保三角形中心对齐文本中心 */
        border-width: 5px;
        /* 设置三角形的大小 */
        border-style: solid;
        border-color: steelblue transparent transparent transparent;
        /* 只给上边框设置颜色，形成倒三角形 */
    }

    .router-link-exact-active {
        font-weight: bold;
    }

    a {
        text-decoration: none;
        color: rgb(0, 0, 0);
    }

    .button-side {
        position: fixed;
        right: 30px;
        bottom: 30px;
        color: rgb(255, 255, 255);
        font-size: 18px;
        cursor: pointer;
        display: fixed;
        width: 100px;
        height: 100px;
        z-index: 15;
    }

    .button-toTop {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 1px solid #ffffff; */
        width: 100px;
        height: 100px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 18px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    }

    .button-toTop:hover {
        background-color: rgba(0, 0, 0, 0.4);
    }

    @media (max-width: 1080px) {
        .menu-btn {
            display: block;
            /* 显示按钮 */
        }

        .sidebar-open {
            display: block;
        }

        #logo1 {
            position: absolute;
            left: 2vw;
        }

        .links,
        #wx1,
        #EN1 {
            display: none;
            /* 隐藏导航栏 */
        }

        .router-link-active::after {
            content: '';
            /* 必须设置，否则伪元素不会显示 */
            position: absolute;
            /* 绝对定位，相对于其父元素（.router-link-active） */
            top: 26%;
            /* 将倒三角定位到链接文本的下方 */
            left: 20%;
            /* 将倒三角定位到链接文本的中心 */
            margin-left: -5px;
            /* 微调位置，确保三角形中心对齐文本中心 */
            border-width: 5px;
            /* 设置三角形的大小 */
            border-style: solid;
            border-color: steelblue transparent transparent transparent;
            /* 只给上边框设置颜色，形成倒三角形 */
            transform: translateY(5px) rotate(-90deg);
            /* 使用 rotate 来旋转 180 度 */
        }

    }
</style>