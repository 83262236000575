<template>
    <!-- 导航 -->
    <div class="Nav">
        <router-link class="linksElement" to="/">首页</router-link>>
        <router-link class="linksElement" to="/about">关于NIJIANG</router-link>>
        <router-link class="linksElement" to="/about/profile">工作室简介</router-link>
    </div>
    <!-- 正文 -->
    <div class="container">
        <div class="about">
            <div class="aboutText">
                <div style="display:flex;align-items:center;margin:0vh">
                    <h1 style="color:aquamarine">NIJIANG工作室</h1>
                </div>
                <h4 style="color:grey;margin:1vh;">NIJIANG Studio</h4>
                <div class="paragraph" ref="fadeInText">
                    <p>NIJIANG工作室是集设计、开发、运营和服务为一体的MC新生代小游戏服务器；
                        工作室成立于2024年，现有成员6余人，有信息服务工程师，插件开发工程师，游戏策划师，建筑设计师，产品经理等职位。</p>
                    <p>工作室小游戏项目现有：麦块大战僵尸以及相关的各种衍生模式。</p>
                    <p>NIJIANG工作室目前已运营超过三个月，工作室成员兢兢业业，长了七八个肝，目的就是为各大MC玩家提供高质量游戏体验!</p>
                </div>
            </div>
        </div>
    </div>

    <!-- 其他 -->
    <div class="container" style="background-color:ghostwhite;">
        <div class="title">
            <p1>视频中心</p1>
        </div>
        <img class="img" :src="require('/src/assets/video.jpg')">
    </div>
    <div class="container">
        <div class="text">
            <p style="font-size:24px">成员合照</p>
            <p style="color:gainsboro">MEMBERS GROUP PHOTOS</p>
        </div>
        <img class="img" :src="require('/src/assets/2.png')">
    </div>
</template>

<script>
    export default {
        name: 'honorView',
    };
</script>

<style scoped>
    .img {
        margin: 1vh 1vh 5vh 1vh;
        width: 80%;
    }

    .text {
        text-align: center;
    }

    .title {
        font-size: 24px;
        color: lightblue;
        display: flex;
    }

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        padding-top: 5vh;
        padding-bottom: 5vh;
        margin-top: 5vh;
        flex-direction: column;
    }

    .about {
        font-size: 18px;
        width: 90%;
        max-width: 800px;
        height: auto;
    }

    .aboutText {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        flex-direction: column;
    }

    .paragraph p {
        text-indent: 2em;
        /* 2em 大约等于两个字的宽度 */
        margin-bottom: 2vh;
        /* 设置段前空行的大小，可以根据需要调整 */
    }

    .paragraph p {
        text-indent: 2em;
        /* 2em 大约等于两个字的宽度 */
        margin-bottom: 2vh;
        /* 设置段前空行的大小，可以根据需要调整 */
    }

    .router-link-active {
        text-decoration: none;
        color: steelblue !important;
    }

    a {
        text-decoration: none;
        color: black;
    }

    .router-link-exact-active {
        font-weight: bold !important;
    }

    .Nav {
        /* position: absolute; */
        display: flex;
        margin: 1vw 1vw 1vw 20vw;
    }
</style>