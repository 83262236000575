<template>
    <!-- Navigation -->
    <div class="menu">
        <router-link class="linksElement" to="/">首页</router-link>>
        <router-link class="linksElement" to="/product">玩法介绍</router-link>>
        <router-link class="linksElement" to="/product/sort">游戏分类</router-link>
    </div>

    <div class="container">
        <Swiper :modules="modules" :pagination="{ clickable: true }" :slides-per-view="1">
            <SwiperSlide v-for="(slide, index) in groupedImages" :key="index">
                <!-- Wrap each slide with router-link -->
                <router-link :to="slide[0].descriptions[0].route" class="product-link">
                    <div class="product">
                        <div v-for="image in slide" :key="image.url" class="image-container">
                            <div class="title">{{ image.title }}</div>
                            <img class="img" :src="image.url" alt="">
                            <div v-for="desc in image.descriptions" class="description" :key="desc.text">
                                <router-link :to="desc.route">{{ desc.text }}</router-link>
                            </div>
                        </div>
                    </div>
                </router-link>
            </SwiperSlide>
        </Swiper>
    </div>
</template>

<script>
    import {
        Pagination
    } from 'swiper';
    import {
        Swiper,
        SwiperSlide
    } from 'swiper/vue';
    import 'swiper/css';
    import 'swiper/css/pagination';

    export default {
        name: 'productSort',
        components: {
            Swiper,
            SwiperSlide,
        },
        data() {
            return {
                modules: [Pagination],
                images: [{
                        title: '麦块大战僵尸',
                        url: require('/src/assets/5.png'),
                        descriptions: [{
                                text: '竞技场模式',
                                route: '/product/sAll/s1/p1_1'
                            },
                            {
                                text: '闯关模式',
                                route: '/product/sAll/s1/p1_2'
                            },
                            {
                                text: '团战僵王',
                                route: '/product/sAll/s1/p1_3'
                            }
                        ],
                    },
                    {
                        title: '金麦块之战',
                        url: require('/src/assets/10.png'),
                        descriptions: [{
                                text: '排位模式',
                                route: '/product/sAll/s2/p2_1'
                            },
                            {
                                text: '狂暴模式',
                                route: '/product/sAll/s2/p2_2'
                            },
                            {
                                text: '恭喜发财',
                                route: '/product/sAll/s2/p2_3'
                            }
                        ],
                    },
                    {
                        title: '黑神话MC',
                        url: require('/src/assets/16.png'),
                        descriptions: [{
                                text: '主线',
                                route: '/product/sAll/s2/p2_3'
                            },
                            {
                                text: '故事',
                                route: '/product/sAll/s2/p2_3'
                            },
                            {
                                text: '联机',
                                route: '/product/sAll/s2/p2_3'
                            }
                        ],
                    },
                    {
                        title: '瓦罗兰块',
                        url: require('/src/assets/17.png'),
                        descriptions: [{
                                text: '经典团战',
                                route: '/product/sAll/s2/p2_3'
                            },
                            {
                                text: '单挑',
                                route: '/product/sAll/s2/p2_3'
                            },
                            {
                                text: '极速模式',
                                route: '/product/sAll/s2/p2_3'
                            }
                        ],
                    },
                    {
                        title: '瓦罗兰块',
                        url: require('/src/assets/17.png'),
                        descriptions: [{
                                text: '经典团战',
                                route: '/product/sAll/s2/p2_3'
                            },
                            {
                                text: '单挑',
                                route: '/product/sAll/s2/p2_3'
                            },
                            {
                                text: '极速模式',
                                route: '/product/sAll/s2/p2_3'
                            }
                        ],
                    },
                    {
                        title: '瓦罗兰块',
                        url: require('/src/assets/17.png'),
                        descriptions: [{
                                text: '经典团战',
                                route: '/product/sAll/s2/p2_3'
                            },
                            {
                                text: '单挑',
                                route: '/product/sAll/s2/p2_3'
                            },
                            {
                                text: '极速模式',
                                route: '/product/sAll/s2/p2_3'
                            }
                        ],
                    },
                    {
                        title: '瓦罗兰块',
                        url: require('/src/assets/17.png'),
                        descriptions: [{
                                text: '经典团战',
                                route: '/product/sAll/s2/p2_3'
                            },
                            {
                                text: '单挑',
                                route: '/product/sAll/s2/p2_3'
                            },
                            {
                                text: '极速模式',
                                route: '/product/sAll/s2/p2_3'
                            }
                        ],
                    },
                    {
                        title: '瓦罗兰块',
                        url: require('/src/assets/17.png'),
                        descriptions: [{
                                text: '经典团战',
                                route: '/product/sAll/s2/p2_3'
                            },
                            {
                                text: '单挑',
                                route: '/product/sAll/s2/p2_3'
                            },
                            {
                                text: '极速模式',
                                route: '/product/sAll/s2/p2_3'
                            }
                        ],
                    },
                ],
            };
        },
        computed: {
            groupedImages() {
                // This logic groups every 4 images into a slide
                let groupSize = 4;
                return this.images.reduce((acc, image, index) => {
                    const groupIndex = Math.floor(index / groupSize);
                    if (!acc[groupIndex]) {
                        acc[groupIndex] = [];
                    }
                    acc[groupIndex].push(image);
                    return acc;
                }, []);
            }
        }
    };
</script>

<style scoped>
    .img {
        width: 100%;
        height: 50vh;
        object-fit: cover;
        /* 裁剪图片，保持比例填满容器 */
    }

    .router-link-active {
        text-decoration: none;
        color: steelblue !important;
    }

    .router-link-exact-active {
        font-weight: bold !important;
    }

    a {
        text-decoration: none;
        color: black;
    }

    .menu {
        display: flex;
        margin: 1vw 1vw 1vw 20vw;
    }

    .img {
        width: 100%;
        height: 18vw;
        display: block;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }

    .description {
        text-align: center;
        font-size: 16px;
        color: white;
        background-color: rgba(0, 0, 0, 0.1);
        padding: 5px;
    }

    .title {
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 48px;
        color: rgb(0, 0, 0);
        padding: 5px;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        white-space: nowrap;
        overflow: hidden;
        /* 隐藏溢出的文本 */
        text-overflow: ellipsis;
        /* 超出时显示省略号 */
    }

    .product {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    .image-container {
        width: 25vw;
        height: auto;
        flex-basis: 20%;
        position: relative;
        padding-bottom: 80px;
    }

    .container {
        width: 100%;
        padding: 10vh 0;
    }

    .linksRow {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1vw;
        padding: 1vw;
    }
</style>