<template>
    <!-- 导航 -->
    <div class="menu">
        <router-link class="linksElement" to="/">首页</router-link>>
        <router-link class="linksElement" to="/news">新闻中心</router-link>>
        <router-link class="linksElement" to="/news/cnews">目录</router-link>
    </div>

    <!-- 正文 -->
    <div class="new-box">

        <ul>
            <li v-for="(item, index) in newsItems" :key="index">
                <div class="time tran3">
                    <span class="mon">{{ item.month }}</span>
                    <span class="day">{{ item.day }}</span>
                </div>
                <div class="new-txt">
                    <router-link :to="item.link">
                        <div class="img">
                            <img class="img" :src="item.imageSrc" alt="News Image">
                        </div>
                        <div class="txt-box">
                            <div class="title tran3">{{ item.title }}</div>
                            <div class="txt">{{ item.content }}</div>
                        </div>
                    </router-link>
                </div>
            </li>
        </ul>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                newsItems: [{
                        month: '10',
                        day: '24',
                        title: '新游戏-麦块大战僵尸',
                        content: '2024年10月，NIJIANG工作室推出了麦块大战僵尸。',
                        imageSrc: require('@/assets/2.png'),
                        link: '/news/new2'
                    },
                    {
                        month: '10',
                        day: '04',
                        title: 'NIJIANG工作室的创始',
                        content: '2024年10月，NIJIANG工作室创建。',
                        imageSrc: require('@/assets/5.png'),
                        link: '/news/new1'
                    },
                    // Add more news items here
                ]
            };
        }
    };
</script>

<style scoped>
    .router-link-active {
        text-decoration: none;
        color: steelblue !important;
    }

    .router-link-exact-active {
        font-weight: bold !important;
    }

    a {
        text-decoration: none;
        color: black;
    }

    .menu {
        /* position: absolute; */
        display: flex;
        margin: 1vw 1vw 1vw 20vw;
    }

    .new-box {
        overflow: hidden;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        margin-top: 3.5%;
        margin-bottom: 3.5%;
    }

    .new-box ul {
        overflow: hidden;
        margin-bottom: -8px;
        min-height: 400px;
    }

    ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
    }

    .new-box ul li {
        overflow: hidden;
        position: relative;
        padding-top: 5px;
        margin-bottom: 8px;
    }

    .new-box:before {
        width: 1px;
        height: 100%;
        position: absolute;
        top: 15px;
        content: '';
        display: block;
        background: #e7e7e7;
        left: 73px;
    }

    .new-box ul li:after {
        height: 1px;
        width: 100%;
        background: #e7e7e7;
        content: '';
        display: block;
        margin-left: 33px;
        margin-top: 5px;
    }

    .new-box ul li .time {
        position: absolute;
        font-weight: bold;
        height: 36px;
        line-height: 36px;
        top: 50%;
        margin-top: -18px;
        color: #000000;
    }

    .tran3 {
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    .new-box ul li .time .mon {
        font-size: 16px;
        width: 24px;
        text-align: center;
        display: block;
        float: left;
        text-align: center;
        margin-right: 20px;
    }

    .new-box ul li .time .day {
        font-size: 24px;
        text-align: center;
        display: block;
        float: left;
        width: 32px;
        text-align: center;
    }

    .new-box ul li .time:after {
        background: #e7e7e7;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        content: '';
        display: block;
        position: absolute;
        top: 13px;
        left: 29px;
    }

    .new-box ul li .new-txt {
        overflow: hidden;
        margin-left: 102px;
    }

    .new-box ul li .new-txt a {
        display: block;
        overflow: hidden;
        padding: 10px;
    }

    .new-box ul li .new-txt .img {
        float: left;
        overflow: hidden;
        /* width: 157px; */
        height: 112px;
        margin-right: 30px;
        object-fit: cover;
        /* 裁剪图片，保持比例填满容器 */
    }

    .new-box ul li .txt-box .title {
        overflow: hidden;
        font-size: 20px;
        color: #343434;
        line-height: 22px;
        height: 22px;
        margin-bottom: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>