<template>
    <!-- 菜单 -->
    <img class="img" :src="require('/src/assets/18.png')">
    <div class="menu">
        <!-- 菜单选项 -->
        <router-link class="menuItem" to="/news/cnews">工作室新闻</router-link>
    </div>

    <!-- 主体内容 -->
    <router-view></router-view>

</template>

<script>
    export default {

    };
</script>

<style scoped>
    .img {
        width: 100%;
        height: 50vh;
        object-fit: cover;
        /* 裁剪图片，保持比例填满容器 */
    }

    .menu {
        width: 100%;
        height: 5vh;
        /* 增加菜单高度 */
        font-size: 18px;
        display: flex;
        transform: translateY(-100%);
        /* 垂直居中 */
        justify-content: space-around;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.5);
        /* 蓝色透明背景 */
    }

    .menuItem {
        text-align: center;
        width: 20%;
        height: 100%;
        /* 调整菜单项高度 */
        color: white;
        display: flex;
        text-decoration: none;
        border-radius: 10px;
        justify-content: center;
        align-items: center;
        transition: width 0.5s ease, background-color 0.5s ease;
        /* 添加宽度和背景色的过渡效果 */
    }

    .menuItem:hover {
        width: 25%;
        background-color: rgba(255, 255, 255, 0.2);
    }

    .menuItem:not(:hover) {
        width: 20%;
    }
</style>