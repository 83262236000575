<template>
    <!-- 底部栏 -->
    <div class="bottom">
        <img id="logo" @click="redirectToRoute" :src="require('/src/assets/nijiang2.png')">
        <div class="linksRow">
            <p1>© 2024 NIJIANG 版权所有</p1>
            <p1>版权所有: NIJIANG工作室</p1>
            <a href="https://beian.miit.gov.cn">网站备案: 鄂ICP备2024071299号</a>
            <span>
                <img class="logos" :src="require('/src/assets/备案图标.png')" alt="备案图标"
                    style="width:16px;display:inline-block;position: relative;top:4px">
                <a href="https://beian.mps.gov.cn/#/query/webSearch?code=42068302000391" rel="noreferrer"
                    target="_blank" style="text-decoration: none; color: #FFFFFF; margin-right: 10px;">
                    鄂公网安备42068302000391
                </a>
            </span>
        </div>
        <div class="linksRow">
            <router-link class="linksElementB" :to="{ name: '关于' }">关于NIJIANG</router-link>
            <router-link class="linksElementB" :to="{ name: '游戏' }">玩法介绍</router-link>
            <router-link class="linksElementB" :to="{ name: '新闻' }">新闻中心</router-link>
            <router-link class="linksElementB" :to="{ name: '支持' }">玩家服务</router-link>
        </div>

        <div class="linksRow" :style="{ display: !siblingRoutes ? 'none' : 'flex' }">
            <!-- 显示当前同级路由列表 -->
            <li v-for="siblingRoute in siblingRoutes" :key="siblingRoute.path">
                <router-link :to="siblingRoute.path">{{ siblingRoute.name }}</router-link>
            </li>
        </div>

        <div class="linksRow">
            <img style="width:100px" :src="require('/src/assets/qqcode.png')">
            <p1>扫码关注NIJIANG</p1>
        </div>
        <div class="linksRow">
            <p>联系我们</p>
            <p1>工作室QQ群：772581408</p1>
            <p1>地址：广东省广州市天河区</p1>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                siblingRoutes: [] // 初始化同级路由列表
            };
        },
        watch: {
            '$route'() {
                // 当路由变化时更新同级路由列表
                this.updateSiblingRoutes();
            }
        },
        methods: {
            updateSiblingRoutes() {
                if (this.$route.path === '/') {
                    this.siblingRoutes = [];
                }
                const matchedRoutes = this.$route.matched;
                if (matchedRoutes.length > 1) {
                    const parentRoute = matchedRoutes[matchedRoutes.length - 2];
                    this.siblingRoutes = parentRoute.children;
                }
            },
            redirectToRoute() {
                this.$router.push('/');
            },
        },
        mounted() {
            // 初始化时也执行一次更新
            this.updateSiblingRoutes();
        }
    };
</script>

<style scoped>
    #logo {
        cursor: pointer;
    }

    .bottom {
        font-size: 12px;
        color: rgb(170, 170, 170);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: row;
        height: auto;
        width: 100%;
        background-color: rgb(44, 44, 44);
        box-sizing: border-box;
        /* 使用盒子模型 */
        z-index: 10;
    }

    .linksRow {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1vw;
        padding: 1vw;
    }

    .linksElementB {
        font-size: 14px;
    }

    .img {
        width: 10vh;
    }

    .router-link-active {
        text-decoration: none;
        color: steelblue;
        position: relative;
        /* 设置为相对定位，以便为后续的伪元素定位 */
    }

    .router-link-active::after {
        content: '';
        /* 必须设置，否则伪元素不会显示 */
        position: absolute;
        /* 绝对定位，相对于其父元素（.router-link-active） */
        top: 50%;
        /* 将三角形定位到链接文本的中心 */
        right: -10px;
        /* 将三角形定位到链接文本的右侧，此处的值可根据需要进行微调 */
        transform: translateY(-50%);
        /* 垂直居中 */
        border-width: 4px;
        /* 设置三角形的大小 */
        border-style: solid;
        border-color: transparent steelblue transparent transparent;
        /* 只给右边框设置颜色，形成左三角形 */
    }

    .router-link-exact-active {
        font-weight: bold;
    }

    a {
        text-decoration: none;
        color: white;
    }
</style>