<template>
    <!-- 导航 -->
    <div class="Nav">
        <router-link class="linksElement" to="/">首页</router-link>>
        <router-link class="linksElement" to="/about">关于NIJIANG</router-link>>
        <router-link class="linksElement" to="/about/honor">工作室荣耀</router-link>
    </div>

    <!-- 正文 -->

    <div class="container">
        <div class="imgBox" style="">
            <Swiper :modules="modules" :pagination="{ clickable: true }" :autoplay="{ delay: 3000 }"
                :initialSlide="currentSlideIndex">
                <swiper-slide class="swiper-slide1" v-for="(certification, index) in certifications" :key="index">
                    <img :src="certification.imgUrl" draggable="false">
                    <p>{{ certification.text }}</p>
                </swiper-slide>
            </Swiper>
        </div>
    </div>
    <div class="container2">
        <Swiper :modules="modules" :pagination="{ clickable: true }" :autoplay="{ delay: 12000 }" :slides-per-view="1">
            <SwiperSlide v-for="(slide, index) in groupedImages" :key="index">
                <div class="product">
                    <div v-for="image in slide" :key="image.url" class="image-container">
                        <img class='img' :src="image.imgUrl" alt="" @click="changeCurrentSlide(index, $index)">
                        <div class="description">{{ image.text }}</div>
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
    </div>
</template>

<script>
    import {
        Pagination,
        Autoplay
    } from 'swiper';
    import {
        Swiper,
        SwiperSlide
    } from 'swiper/vue';
    import 'swiper/css';
    import 'swiper/css/pagination';
    import 'swiper/css/navigation';

    export default {
        name: 'honorView',
        components: {
            Swiper,
            SwiperSlide,
        },
        data() {
            return {
                modules: [Pagination, Autoplay],
                certifications: [{
                        imgUrl: require('/src/assets/9.png'),
                        text: "游戏截图资料"
                    },
                    {
                        imgUrl: require('/src/assets/19.png'),
                        text: "游戏截图资料"
                    },
                    {
                        imgUrl: require('/src/assets/3.png'),
                        text: "游戏截图资料"
                    },
                    {
                        imgUrl: require('/src/assets/4.png'),
                        text: "游戏截图资料"
                    },
                    {
                        imgUrl: require('/src/assets/20.png'),
                        text: "游戏截图资料"
                    },
                    {
                        imgUrl: require('/src/assets/21.png'),
                        text: "游戏截图资料"
                    },
                    {
                        imgUrl: require('/src/assets/8.png'),
                        text: "游戏截图资料"
                    },
                    {
                        imgUrl: require('/src/assets/5.png'),
                        text: "游戏截图资料"
                    },
                    {
                        imgUrl: require('/src/assets/22.png'),
                        text: "游戏截图资料"
                    },
                    {
                        imgUrl: require('/src/assets/1.png'),
                        text: "游戏截图资料"
                    },
                    {
                        imgUrl: require('/src/assets/2.png'),
                        text: "游戏截图资料"
                    },
                    {
                        imgUrl: require('/src/assets/6.png'),
                        text: "游戏截图资料"
                    },
                    // Add other certifications here
                ],
                currentSlideIndex: 0,
            };
        },
        computed: {
            groupedImages() {
                // This logic groups every 4 images into a slide
                let groupSize = 4;
                return this.certifications.reduce((acc, certifications, index) => {
                    const groupIndex = Math.floor(index / groupSize);
                    if (!acc[groupIndex]) {
                        acc[groupIndex] = [];
                    }
                    acc[groupIndex].push(certifications);
                    return acc;
                }, []);
            }
        },
        methods: {
            // 更新当前选择的图片索引
            changeCurrentSlide(groupIndex, imageIndex) {
                // 计算在整体图片数组中的索引
                const overallIndex = groupIndex * 4 + imageIndex;
                this.currentSlideIndex = overallIndex;
                console.log("点击了");
            }
        },
    };
</script>

<style scoped>
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        padding-top: 5vh;
        padding-bottom: 5vh;
        margin-top: 5vh;
        flex-direction: column;
        /* 添加垂直方向的居中 */
    }

    .imgBox {
        background-color: #f5f5f5;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 80vw;
        border: 2px solid #f0f0f0;
        box-shadow: 0px 0px 20px rgb(0, 0, 0, 0.1);
        border-radius: 20px;
    }

    .floor {
        display: flex;
        justify-content: space-around;
        /* 让图片均匀分布在父容器中 */
        margin-top: 5vh;
        /* 上边距 */
        margin-bottom: 5vh;
        /* 上边距 */
        margin-left: auto;
        /* 自动调整左边距 */
        margin-right: auto;
        /* 自动调整右边距 */
        width: 80%;
        /* 设置父容器的宽度，根据需要调整 */
    }

    .swiper-slide1 {
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        border-radius: 20px;
    }

    .swiper-slide1 img {
        width: 100%;
        height: 500px;
        object-fit: cover;
        /* 裁剪图片，保持比例填满容器 */
        border-radius: 20px;
    }

    .swiper-slide1 p {
        text-align: center;
        font-size: 1.5vw;
        /* 或者您想要的字体大小 */
        color: gray;
    }

    .router-link-active {
        text-decoration: none;
        color: steelblue !important;
    }

    a {
        text-decoration: none;
        color: black;
    }

    .router-link-exact-active {
        font-weight: bold !important;
    }

    .Nav {
        /* position: absolute; */
        display: flex;
        margin: 1vw 1vw 1vw 20vw;
    }

    .img {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        width: 20vw;
        height: auto;
        max-height: 20vw;
        display: block;
        box-shadow: 0px 0px 20px rgb(0, 0, 0, 0.2);
    }

    .description {
        text-align: center;
        font-size: 16px;
        color: white;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 5px;
        box-shadow: 0px 0px 20px rgb(0, 0, 0, 0.2);
    }

    .product {
        display: flex;
        justify-content: space-around;
        flex-wrap: nowrap;
    }

    .image-container {
        display: block;
        justify-content: center;
        align-items: center;
        flex-direction: row;

        width: 20vw;
        height: 30vw;
        position: relative;
        padding: 10px;
        padding-bottom: 240px;

        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            /* height: 100%; */
            object-fit: cover;
            /* 裁剪图片，保持比例填满容器 */
        }

        padding: 5% 0;

    }

    .container2 {
        background-color: #f5f5f5;
        width: 100%;
        margin-bottom: 100px;
    }
</style>