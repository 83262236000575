<template>
    <!-- 导航 -->
    <div class="menu">
        <router-link class="linksElement" to="/">首页</router-link>>
        <router-link class="menuItem" to="/contact">联系我们</router-link>>
        <router-link class="menuItem" to="/contact/rt">招贤纳士</router-link>
    </div>
    <!-- 主体内容 -->
    <div class="job-box transition-box">

        <div class="job-top">
            寻找当前在NIJIANG工作室的工作机会，请将简历发送至
            <span>3531341457@qq.com</span>&nbsp;
        </div>

        <div class="job-search">

            <div>城市：</div>

            <div class="select-container" id="city-select">
                <label for="city">广州</label>
                <select name="city" id="city">
                    <option value="28">广州</option>
                </select>
            </div>

            <div class="select-container">
                <label for="career">职位名称</label>
                <div id="career-select">
                    <select name="career" id="career">
                        <option style="display:none;" value="0">请选择</option>
                        <option value="188">插件开发</option>
                        <option value="189">游戏策划</option>
                    </select>
                </div>
            </div>

        </div>

        <div class="zp-tab">

            <div class="zp-tabt">
                <span class="zp-name">职位名称</span>
                <span class="zp-add">工作地点</span>
                <span class="zp-time">发布日期</span>
                <span class="zp-num">招聘人数</span>
            </div>

            <div class="zp-tt">
                <div class="zp-li">

                    <div v-for="(job, index) in jobs" :key="index">
                        <div @click="toggleJob(index)" class="top" :class="{ 'top on': job.open }">

                            <span class="zp-name">{{ job.name }}</span>
                            <span class="zp-add">{{ job.location }}</span>
                            <span class="zp-time">{{ job.date }}</span>
                            <span class="zp-num">{{ job.count }}</span>

                        </div>

                        <div class="txt" :class="{ 'txt on': job.open }">

                            <p>{{ job.details }}</p>
                            <p class="ts">任职资格：</p>
                            <p v-for="(requirement, rIndex) in job.requirements" :key="rIndex">{{ rIndex + 1 }}、
                                {{ requirement }}
                            </p>
                            <p class="ts">任职要求：</p>
                            <p v-for="(requirement, rIndex) in job.qualifications" :key="rIndex">{{ rIndex + 1 }}、
                                {{ requirement }}
                            </p>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                jobs: [{
                        name: "插件开发",
                        location: "广州",
                        date: "2024-10-05",
                        count: 1,
                        details: "广州|大专以上|1年经验|¥8-12K",
                        open: false, // 初始状态为关闭
                        requirements: [
                            "负责MC游戏技术开发",
                            "擅长Java开发",
                            "熟悉MC常见服务端的API",
                            "熟悉计算机操作办公软件"
                        ],
                        qualifications: [
                            "1年以上MC服务器开发经验",
                            "管理过MC服务器优先",
                            "有插件项目成品优先"
                        ]
                    },
                    {
                        name: "游戏策划",
                        location: "广州",
                        date: "2024-10-05",
                        count: 1,
                        details: "广州|双一流以上|1年经验|¥8-12K",
                        open: false, // 初始状态为关闭
                        requirements: [
                            "负责游戏内容的组织和策划",
                            "对人力资源管理事务性工作有娴熟的处理技巧",
                            "熟悉工作室的招聘流程及各种招聘渠道",
                            "熟悉计算机操作办公软件"
                        ],
                        qualifications: [
                            "1年以上工作室招聘主管工作经验",
                            "游戏爱好者优先",
                            "有三支柱模式操作经验优先"
                        ]
                    },
                    // 其他招聘栏目数据
                ]
            };

        },
        methods: {
            toggleJob(index) {
                this.jobs[index].open = !this.jobs[index].open; // 切换状态
            }
        }
    }
</script>


<style scoped>
    .router-link-active {
        text-decoration: none;
        color: steelblue !important;
    }

    a {
        text-decoration: none;
        color: black;
    }

    .router-link-exact-active {
        font-weight: bold !important;
    }

    .menu {
        /* position: absolute; */
        display: flex;
        margin: 1vw 1vw 1vw 20vw;
    }

    .zp-box {
        max-width: 60vw;
        margin-left: auto;
        margin-right: auto;
        padding-top: 1.8%;
        padding-bottom: 4%;
    }

    .zp-box .zp-top {
        font-size: 14px;
        color: #000000;
        line-height: 26px;
        overflow: hidden;
        font-weight: 500;
        margin-bottom: 3%;
    }

    .zp-box .zp-city {
        overflow: hidden;
        line-height: 29px;
        margin-bottom: 2.5%;
    }

    .zp-box .zp-city span {
        font-size: 14px;
        color: #000000;
        line-height: 29px;
        float: left;
        padding-right: 1px;
    }

    .zp-box .zp-se {
        height: 29px;
        width: 251px;
        float: left;
        background: url() no-repeat center center;
        position: relative;
        margin-right: 9px;
    }

    .zp-box .zp-se label {
        font-size: 12px;
        color: #ffffff;
        display: block;
        line-height: 29px;
        padding-left: 23px;
    }

    .zp-box .zp-se select {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0px;
        left: 0px;
        opacity: 0;
    }

    select:not(:-internal-list-box) {
        overflow: visible !important;
    }

    select {
        text-rendering: auto;
        color: -internal-light-dark(black, white);
        letter-spacing: normal;
        word-spacing: normal;
        line-height: normal;
        text-transform: none;
        text-indent: 0px;
        text-shadow: none;
        display: inline-block;
        text-align: start;
        appearance: auto;
        box-sizing: border-box;
        align-items: center;
        white-space: pre;
        -webkit-rtl-ordering: logical;
        background-color: -internal-light-dark(rgb(255, 255, 255), rgb(59, 59, 59));
        cursor: default;
        margin: 0em;
        border-width: 1px;
        border-style: solid;
        border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
        border-image: initial;
        border-radius: 0px;
    }

    option {
        font-weight: normal;
        display: block;
        white-space: nowrap;
        min-height: 1.2em;
        padding: 0px 2px 1px;
    }

    .zp-tab {
        overflow: hidden;
    }

    .zp-tab .zp-tabt {
        overflow: hidden;
        background: #ededef;
        height: 44px;
        line-height: 44px;
        font-size: 14px;
        color: #000000;
        font-weight: 500;
        margin-bottom: 15px;
    }

    .zp-tt {
        overflow: hidden;
    }

    .zp-tt .txt {
        margin: 20px 16px 20px 16px;
        overflow: hidden;
        padding-bottom: 0;
        max-height: 0;
        border-bottom: 2px solid rgba(121, 134, 171, 0);
        /* 初始状态下透明边框 */
        transition: max-height 0.5s ease, border-color 0.5s ease;
        /* 过渡 max-height 和 border-color */
    }

    .zp-tt .txt.on {
        max-height: 500px;
        /* 或者其他足够高的值，使得内容完全展开 */
        border-bottom-color: rgba(121, 134, 171, 1);
        /* 边框展开时的不透明边框颜色 */
        margin-top: 0;
        /* 修正顶部边距 */
    }

    .zp-tt .txt .ts {
        font-weight: bold;
    }

    .zp-tt .txt p {
        color: #071f64;
        line-height: 24px;
        font-size: 14px;
    }

    .zp-tt .top {
        overflow: hidden;
        font-size: 14px;
        line-height: 35px;
        color: #6c6d78;
        background-color: #ffffff;
        font-weight: 400;
        cursor: pointer;
    }

    .zp-tt .top.on {
        color: #071f64;
        background: #ededef;
    }

    .zp-tab .zp-name {
        display: block;
        float: left;
        width: 20%;
        padding-left: 16px;
    }

    .zp-tab .zp-time {
        display: block;
        float: left;
        width: 25%;
        text-align: center;
    }

    .zp-tab .zp-num {
        display: block;
        float: right;
        width: 20%;
        text-align: center;
        padding-right: 16px;
    }

    .zp-tab .zp-add {
        display: block;
        float: left;
        width: 20%;
        text-align: center;
        margin-right: 0%;
    }


    .job-box {
        max-width: 80vw;
        margin-left: auto;
        margin-right: auto;
        padding-top: 1.8%;
        padding-bottom: 4%;
    }

    .job-box .job-top {
        font-size: 14px;
        color: #000000;
        line-height: 26px;
        overflow: hidden;
        font-weight: 500;
        margin-bottom: 3%;
    }

    .job-box .job-search {
        overflow: hidden;
        line-height: 29px;
        margin-bottom: 2.5%;
    }

    .job-box .job-search span {
        font-size: 14px;
        color: #000000;
        line-height: 29px;
        float: left;
        padding-right: 1px;
    }

    .job-box .select-container {
        height: 29px;
        width: 251px;
        float: left;
        background: url('/src/assets/zp-city.png') no-repeat center center;
        position: relative;
        margin-right: 9px;
    }

    .job-box .select-container label {
        font-size: 12px;
        color: #ffffff;
        display: block;
        line-height: 29px;
        padding-left: 23px;
    }

    .job-box .select-container select {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0px;
        left: 0px;
        opacity: 0;
    }
</style>